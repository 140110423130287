import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Modes of Transport",
  "description": "A brief about the selection of transport modes from our factories to your doorstep.",
  "author": "Ally Cheng",
  "categories": ["shipping-packaging"],
  "date": "2022-05-13T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`The modes of transport are defined as the transport forms created by the application of various carriers and routes under different management. To put it bluntly, it means how a commodity gets from the place of origin to the destination through various mediums and routes. In choosing the different modes of transport, many factors such as the specificity of the commodity, timeliness, and control of transport costs should be first considered. `}</p>
    <p>{`Accounting for a large proportion of the total cost of logistics, the transport cost is a dominant one therein. As different transport modes may lead to different logistic costs, it is essential to choose a proper transport mode. What exactly are the transport modes? What are their respective strengths and weaknesses? `}</p>
    <h2 {...{
      "id": "influencing-factors"
    }}>{`Influencing factors`}</h2>
    <p>{`The transport system is made up of several factors, which include: the type and nature of the carriers, transport routes, also the ports or stations that are used or involved. The selection of the modes of transport to be used is firstly based on the factors mentioned. Other than those, the transport process is also  affected by the weather, the presence of equipment and other facilities, as well as the main technical and economic indicators. `}</p>
    <h2 {...{
      "id": "classification"
    }}>{`Classification`}</h2>
    <p>{`In general, NexPCB classifies our transport modes into five, including highway, railway, waterway, aviation, and pipeline. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Highway transport`}</strong>{` `}</li>
    </ul>
    <p>{`Highway transport is a mode of transport where cars and other vehicles like human- or animal-drawn carts are used to carry goods along the highway. It mainly serves short-distance freight transport and small quantities of cargoes, which runs for long distances and carries large quantities towards areas that are not readily accessible for waterway and railway transports. Its leading advantages include its high flexibility, the short construction period of highways, relatively lower cost of transport, easier response to error, moderate requirements in facilities of the destination, and an option for “door to door” transport. Highway transport can be used as means to link different transport modes, and usually covers distances less than 200 kilometers. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "705px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.89575289575289%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACqklEQVQozwGfAmD9ADhASzxBS2xwfE1JVTlCTURKVUhMV0VIVEVKVUVJVERJVIaSnmRvek5YYU9ZYkNNV2Fsd1pjbkBET0JIUQBhJi22HCbbECHqHjGFSlhIWmdWXGhSWGROVGBOU19VXWmhrLhqdH9eaHNcZnFQWmV9iZRZY24/SVNETlgAgxsj/hEV/Bcb/yQk+TMzeFVgTF1pVVtmUFdiUlhjUVZhgouVk56qcnuFe4WRgIuWiZSfjpqljZijkZ2nAG8vP+chJf8qJfsxLP8yK9g7PE9RW0lMVEVGT0ZKU0lOWUFFUYGNmcre6NPg6Nvg597k6uPm7eLl7eLk7AA5KC+6KjT+MC7/Lyr9NTD/NDCqRUlHXGVeY2thZGx0eIOLmqSZm6XBfobRpa75/f/19Pjy8ffu7vTp6fAAKS89fRkf4B0m7zUu5z0v4Dwr6zE22XyHz7vCsrnChI2Xw5un1S4z2R0j0yYw4dbg9ff87+316enx5+nyAB8nOzEOFdMiI85LJdp0MMlWH90XH/9DUdaEjb7N1JmTm400NdgJDt4vPME0R+K3xe/4/urr8ufl7+jg6wBEQ1sfK0K3ISbVJQy5OhS4IQvQFyH3Q1HLc36wwciZY25cCgaJNTxfSFWnJzz4u8v6///y5vHj0t7dwc0AIRwpHyY4ZBYfVwgOKwAIJQMMRjtGd3B+fHB/qLXAnWt4rwMUwBIrth0ziiAyjnR/lI6Rd2dsUkVKIyAlABAOGVFRay4xQwgUHxokMS87SE9eblpneU1TZERCUmVof35NXIgQJDMNGgcVHCMiMCIgLhcaKSwuPyMlNQALCRcrKzs3OUwhIDA9Pk9NT2BGRVY2M0QjHy5EQ1hZWnExOEscMEIbJTUgHy0dHy0eIC8dHiwkJjRYXnA9ExjnH+0dEwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/18bc0d203b954453c28e8bfcf8a62b0a/652f5/transport-01.webp 259w", "/static/18bc0d203b954453c28e8bfcf8a62b0a/c29d2/transport-01.webp 518w", "/static/18bc0d203b954453c28e8bfcf8a62b0a/af945/transport-01.webp 705w"],
            "sizes": "(max-width: 705px) 100vw, 705px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/18bc0d203b954453c28e8bfcf8a62b0a/a2ead/transport-01.png 259w", "/static/18bc0d203b954453c28e8bfcf8a62b0a/6b9fd/transport-01.png 518w", "/static/18bc0d203b954453c28e8bfcf8a62b0a/d2cbc/transport-01.png 705w"],
            "sizes": "(max-width: 705px) 100vw, 705px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/18bc0d203b954453c28e8bfcf8a62b0a/d2cbc/transport-01.png",
            "alt": "Highway Transport",
            "title": "Highway Transport",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Railway transport`}</strong>{` `}</li>
    </ul>
    <p>{`Railway transport is defined as a mode of transport where trains are used to carry goods. It mainly serves freight of long distances and large quantities of goods. In areas where water transport is not available, almost all bulk goods rely on railway transport, causing them to play a major role in trunk line transportation. Its economical distance is usually above 200 kilometers. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "728px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.57915057915058%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC50lEQVQozyXM209TBwCA8fMvTIPIxSKXHAQKSot4KZpa8Vio0FCpUFDqYICVoBZlBRxVIaBctD2Y0sZ4mTOS+KAJMTozdQ9sMWoiWVREagsoN8HOyzBbjJdv2Xz48nv7hMDELMHJOUanQgQnXzEVmmfg7gO8vlN4PB56ZBlZdnNSduPYX4dFL+FUJ3OiIIfKAondUhh9TZE4ikVU4QsRRsan8T+f4b/x2HTofyfm5pn76zOh9xCa/8yf7z/y5u9PvPvwhWBwjHZNOhdqKpGPHsa+ZTGXnHHYpASylyxG8L+YJTDzmkcPhxkcfMTozGsCk6++NhFi/OUbRp5PMRScZGz2LV0dXbSsTuNsRRnnPTI/mL7heHUMBjGabfFRCA//eMzToREe3P6V25ev8HQkwNATP4+H/TwbfcEv/f3YynZgycvDd7ybY42NNCmXcssjM/3PF3rbqihflcDW6Ah2JcUgdLa0MnD+HD93dXDT2cCtY0e44eriWncHNz0nudxUS7dhFW69mr7yfO71/YTXuBF3RSkXvT5Ol5lpS41nt6igIS0OQWcqQmsswVxqpWnPXgauXWc4MM6wf5T79wc5XWvDpc/Glb2JM9Yifvv9Dr2WrTiWK+hNj6ZfSsGnSaIxJZZDGYkImYZS1uZZyMnfgq2ykJYDtXjb2+m/eoNmRz2rU9cgJhlZrykjRalF0mmp36Bhp1LBd+ki+0y5HLSasaxVsz4qDCEmWYW+2EqhUUdPQw7f26uozc/lxAEbdbYKDlmLaMyRqNusp3W7Gaspl5WxkayLjWSlGI82MQ6LKoX8Fctw65QIYlIyRnMh201ZuBwGmveW4LR9yxm5jXZ7NT9W7aA+K4PM6HDOVhRzZNdOxFgFqkULkNRp6NQq9BEL2JYQQXNGPIJhUyY11SXUFGtxVhfQaS+n1V6Or6eTXtlFVvIyssREli4KQ6NMxSu7MBoklifGsXGNitLNGqSEKLSKcOqUS/gXBWTj1uA0pS4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/869e402b57445f68e6454455db6127cb/652f5/transport-02.webp 259w", "/static/869e402b57445f68e6454455db6127cb/c29d2/transport-02.webp 518w", "/static/869e402b57445f68e6454455db6127cb/8cb3e/transport-02.webp 728w"],
            "sizes": "(max-width: 728px) 100vw, 728px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/869e402b57445f68e6454455db6127cb/a2ead/transport-02.png 259w", "/static/869e402b57445f68e6454455db6127cb/6b9fd/transport-02.png 518w", "/static/869e402b57445f68e6454455db6127cb/cecac/transport-02.png 728w"],
            "sizes": "(max-width: 728px) 100vw, 728px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/869e402b57445f68e6454455db6127cb/cecac/transport-02.png",
            "alt": "Railway Transport",
            "title": "Railway Transport",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Water transport`}</strong>{` `}</li>
    </ul>
    <p>{`Water transport is defined as a mode of transport where ships are used to carry goods, which mainly serves transport of large quantities and long distances, and plays a major part in the trunk line transportation. On inland rivers and along coastlines, water transport also usually serves as a small-scale transport mode, supplementing and linking trunk line transportation for bulk goods.  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "699px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.965250965250966%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC0ElEQVQozwXB71OSBwDA8WfXtbntvNpW65Ji9kM4y2Yqh4D1AKaoCCGCPkgCKgrxKxSFsEdUSGy4DrPyR+xYl2l1Z6tXze126/ZmL/ZqL/YHfff5CIGITDS1ykzuMenCDvOrFeTVCotrz3mwecBqaYPILYk7wQSLqRxzo14S3ltM2pwEnSP4LXambMPIK9vczW8izMplMrlHJBML5OQfKf7wjPWdA8pbr3m4UiQ92M94dzPuTg3y3Xkqe4es5Mu4dRpceg0pyUu0Q2TCPkQsModwv1ThQflnsqkF5PEQC74gy5NREr4AM1YLIVM3QzotY70ifkcHM5E4L94cUowkyU+OE3c6CTQ1YlTU0XOmDmFj5yVblVdUd99R3XvH7v57stEYsaEBliSJeFcnNy+p8Om1pAd0eIxGkj4PuWEbWekm05KHtMuNx9yJp12L8PLNW6o/VdkulnhWuE92ws/02Bhp7wgTnSbsmjbEeiUWdQPOZhW+9iakVhXRXhHZN0TUamFLvkd6WGI+FkE4/PU9f338jT//+MCTtSJJqY/w8CBusxHlsVr0jgDXbxdpE/sxaA1YLT3YRD0OQxsDLWpunFOwtTDHw9shqo/XEP775zUf3z5lMewl6DAzPTXC8soiqcwsI44+bMkC7ZkqGtsorR0WDP4MojNAz1iS3gEPJvEGa8UCB6Ul/v37F4Tf9zO8WI+TuzPK8+08+7vrrO9sEFvK0xdOcMnh53v/PQypJ3SkNrk2/QhTvIgxvIw1XqArlMMbjCPbzZSzPoS9pzOU5v2kp1xEPVYGRT26pitcOKfirOIMitOnOK1QolQ3c7G9hxaTE63JzrVuF1ev96PWdXH5fAMtXx+nRXkS4cOrJWbDg0Qm3IQCLszaVi5+ewJF7Rec+rKGE5/X8NVnn3L86BFqj3zCdzVHufDNMa4q62ioP0u9UolareJKo4rGhvP8D6k8pS5/3giYAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/13325443e15f2f15912a05cfd9397936/652f5/transport-03.webp 259w", "/static/13325443e15f2f15912a05cfd9397936/c29d2/transport-03.webp 518w", "/static/13325443e15f2f15912a05cfd9397936/32d5d/transport-03.webp 699w"],
            "sizes": "(max-width: 699px) 100vw, 699px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/13325443e15f2f15912a05cfd9397936/a2ead/transport-03.png 259w", "/static/13325443e15f2f15912a05cfd9397936/6b9fd/transport-03.png 518w", "/static/13325443e15f2f15912a05cfd9397936/3fe45/transport-03.png 699w"],
            "sizes": "(max-width: 699px) 100vw, 699px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/13325443e15f2f15912a05cfd9397936/3fe45/transport-03.png",
            "alt": "Water Transport",
            "title": "Water Transport",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Air transport`}</strong>{` `}</p>
        <p parentName="li">{`Air transport is defined as a type of transport mode where planes or other airborne transports are used to carry goods. Most of the time, it is suitable for the following three types of goods: `}</p>
        <ol parentName="li">
          <li parentName="ol">{`Valuable and transport-friendly goods such as components of expensive equipment and high-grade products. `}</li>
          <li parentName="ol">{`Emergency supplies such as disaster relief materials. `}</li>
          <li parentName="ol">{`Fresh, perishable and seasonable goods. `}</li>
        </ol>
        <p parentName="li">{`In actual logistics operations, three major modes usually adopted are as follows: `}</p>
        <ul parentName="li">
          <li parentName="ul">{`Airliner transport: defined as airplane transport with a fixed schedule, fixed route, and fixed airport of departure and destination, suitable for the transport of emergency goods, and fresh and seasonable products.`}</li>
          <li parentName="ul">{`Chartered carrier transport: defined as an airplane chartered by a single or several consignor(s). It can then be further categorized into a wholly-chartered plane and a partially-chartered one. The former is suitable for transporting large quantities of goods, while the latter is for transportation of goods with several consignors and the same destination airport.`}</li>
          <li parentName="ul">{`Consolidation transport: defined as the process where an air freight forwarder combines several goods to be shipped towards the same destination into one batch. This is done by handling one mater air waybill with the airline company. After arriving at the arrival airport, the combined goods are received and declared at the customs by the agent authorized by the aforesaid forwarder. The goods are then distributed to each actual consignee according to the address stated in the issued air waybill by the forwarder. Consolidation is one of the most popular transport modes used by air freight, and also one of the primary services of air freight forwarding.  `}</li>
        </ul>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "616px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.07335907335908%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADVElEQVQozx3La09bBQCA4fMT/GCMOp0pmg0hEDYmuBkus/RKaWlpOaV0dMAKpVC5FBisdKztaUtLu5aCgA63Bd1wJsa4zCXEjGxBuWxcKhcZoDP6R16jH56Pj1AWeoE8mUX/5QGN373CuvAnlm/+wPrgFY4f/+byw3+wf/8X4sIx9fMvsXy1j3l2n9rpXWqndtCns2jHN5BLzyn3ryKcG17hY/8KF4LrVCW3qb11gPn2S+rnD7HeP6Zh4Rjx3hHWe0c0zB9iub2P6Ys9DJM76G5uoY1voJHWqA/+jN3/A0J5+AVV8U3ksQ0UiS10M/tUT++hTG+jzmTRTe+incz+zzi3h35mh5pMFm1yA1V4DVVgGfPIIg9mpngy60M4079EeXgdTSZLzcwO5rldGuYPqP/6d0xzO9TM/obyv5zaQDOxie7zbXTpTTTxdbRjq9Qln+NMLjOUeIg7cB8hv2eRwv4lzvqWKQltUBFexz7yLeLdI0x3DjHO7aNKb6FMbqKIraNLraGNraANPcUYe4Y4/hQx8QtifBnDyE8IOTVeTotj5NlT5DYmKK33YXdK1HZlaPTP05N+xPU7v+LNPKYr84SOzCp9U88Ynl2iL7WI48Yj5N2P6R25S/vADEJJlYlyg4PzapELShP1RjPe7kF02moulhbT63LhbHFRIJPRrlPRab6E4WIZjQo12goz1go1lk+UDFhEmsVLCIoqPW3tXlyd/fR2ejAoqqjTqhnu7sHt7sHVchmjVkGt3kS7xUivqKTfbWe04TyNmnIiHifpdgNtZR+iypMhnHrnbRyfFuIyV+Ksk2PRlNFcK2eoqRqPKKfDpqO7RcQ34MXR6kI0m3C1dWJrcNDSYMVma0Wt0FGZ/z4Vp99DaGq+Qpe7g8FeD8NXB5AkiUhEIhzwEQ/5iEpB+hwarOpzWPQKmupU2GoqsRu12FRnKTj5GrI3X6f4g3fJP/kWwpW2dj7r8dI/dI1Bn5+rI35GRq8TCPgJSgFCoVGksER0LEo8ESMWjxGNRElIA4y6Kyg+U8CpHBmFshPknngDwd7SirPTg8fbj89/jUgkSDwWJjYWJJ2KMZVJMjkxzkQqxs1xiWjoBunIILeGy1CVF1H6UQklRYUU5eWSm5PDvx+eNj3EAZRTAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/652f5/transport-04.webp 259w", "/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/c29d2/transport-04.webp 518w", "/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/26c8a/transport-04.webp 616w"],
                "sizes": "(max-width: 616px) 100vw, 616px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/a2ead/transport-04.png 259w", "/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/6b9fd/transport-04.png 518w", "/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/40040/transport-04.png 616w"],
                "sizes": "(max-width: 616px) 100vw, 616px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/3dbd1f3b27a5f39eb5b8c13cf87f4442/40040/transport-04.png",
                "alt": "Air Transport",
                "title": "Air Transport",
                "loading": "eager",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Pipeline transport`}</strong>{` `}</p>
      </li>
    </ul>
    <p>{`Pipeline transport is a mode of transport where pipelines are used to carry gases, liquids, and solid slurries. It is achieved by moving objects along the pipeline in the direction of high to low pressure. What sets pipeline transport apart from the other modes of transport is the stationary setup of pipeline transport equipment. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "690px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.98455598455598%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADIklEQVQozwXBe1DTBQDA8d+f/ddRRmMPWSl5HHuALpIdJkQlauQY7AEjNuee/PZEN3dsa7ABm9B1cHgSntZ1QKVpZ1p5BZ1X5l0PedQZRT4QC3pcnnXXHX9++3yE1bV/+eP+Buv3N7j75wYLK/8xd+sB87ce8OPqPyze/pvvb/7F9eXfWb67zi8rv/H53AqLy7+SP7vA1R/u8dPKOl8t3OHG8j0Ed5dItjDG1JnznLtwkenzs0x+Ms+n15Z4b2aJwekbvPHhEqcu/czwu4t0T3xH4s05Pvt2lZnra1y6epuRqa+JDF1mYGIWwdGpZn/zAUymClwHpfi9MtIpDYP9TxNPVBOKv0isJ0D0qA0x1YW3N013fpjM6DjZsXEC/aPEjp0g9fop+kanESwWFQarlZeadtBmKcfpfAGrSYnFJKfDquRIsILDwUoiooSA92FCviJ8hx7B55RwyL6JgK+IaHATh0MSokEFQi5fh7mtAYtZgcezBUuHmQNNJZiNEjptVYzl24kGihE9xcQiMkSvjFCXjGCXHL9LQVSUEAtJiIcfJxkvRsgNvY2pcTeR0BO8mizBbdfgsG/F45Hj9chIRKrIJSrJJnaSicuJ+mTYzCWYDCWYmxUcbNuM0ybH0SYl0S1F6LTUslenxmzqoK/XgLNVis22nUhAgeh5CL+riKD3SVLd9RQyTaQTNYieKlx2PQ6bFrNBSsvLciwGJS57KULjnnJ0ikdp3avB5WjFabfjdljwufYRjTQQ9u/C59LjdyqJR92kkwHSSQPDfTvJ9nhJHGknFi4l36OhkNEhqCvr0BQ/xnZtGVqNilptGcY9O3C078ds6cTRYSMaDhEKi0T8AYJiGqe1jmxPI/mkif7UM/Qny+kN1pPP1CB4HSpaquXYTXqC7hp2VyrZrNxK2LWNkV41Q6mnGB3cxumRfUzkjAwnnIwP7uLi5LPMvGNk6qSK17LVFI7WM5DUIRwvaIkYSjl34nk+nqwl3L4Fra4MY3MFs+/rufKBnvnLUe58eZKbX5xm7ZuzXLvwClfOtPDRWw0c73uOYxk1A2kVuZie/wHIEvug38FG5QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/16580a3417bebb88f154136410f2bfb6/652f5/transport-05.webp 259w", "/static/16580a3417bebb88f154136410f2bfb6/c29d2/transport-05.webp 518w", "/static/16580a3417bebb88f154136410f2bfb6/8efd0/transport-05.webp 690w"],
            "sizes": "(max-width: 690px) 100vw, 690px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/16580a3417bebb88f154136410f2bfb6/a2ead/transport-05.png 259w", "/static/16580a3417bebb88f154136410f2bfb6/6b9fd/transport-05.png 518w", "/static/16580a3417bebb88f154136410f2bfb6/1e043/transport-05.png 690w"],
            "sizes": "(max-width: 690px) 100vw, 690px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/16580a3417bebb88f154136410f2bfb6/1e043/transport-05.png",
            "alt": "Pipeline Transport",
            "title": "Pipeline Transport",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <h2 {...{
      "id": "comparison"
    }}>{`Comparison`}</h2>
    <p><strong parentName="p">{`Comparison of advantages and disadvantages of various transport modes`}</strong>{` `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Transport mode`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Advantages`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Disadvantages`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Highway transport`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Direct door-to-door transport is possible. Economical for short-distance transport. Flexible, able to meet different needs.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Small capacity of each vehicle, not suitable for large quantities. Expensive in the case of long-distance transport.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Railway transport`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Meet the need of efficiently transporting large quantities of goods in one go. Economical when transporting goods that cannot afford high freight charges. Less accident occurrence and provide more safety since tracks are used.  Generally, the railway transport network is complete, allowing the delivery of goods to various destinations. Hardly affected by the weather during transport`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Expensive in the case of short-distance transport. Not suitable for emergency transport. In the case of long-distance transport, the train may stop for a long time on route due to cargo arrangements.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Water transport`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Suitable for large quantities of goods, which cannot afford high freight charges. Suitable for freight with a large volume/weight.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Slower (longer duration of transport). Higher handling fees at harbors. Heavily affected by the weather. Poor accuracy and safety of transport`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Air transport`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Fast in transport speed. Suitable for long-distance transport of small quantities of goods, which can afford high freight charges.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The high freight charge and low transport capacity are not suitable for the transport of goods with low value or those in large quantities. Limited weight and volume of the goods. Not applicable for areas with no airport.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pipeline transport`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`High efficiency. Suitable for the transport of gaseous and liquid goods. Uses less land. Suitable for automated management thanks to its high efficiency.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Stringent requirements in objects to be transported`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "others"
    }}>{`Others`}</h2>
    <p>{`As the transport industry evolves, more advanced transport modes, apart from the five basic ones, have been developed. These additions of transport options have greatly enhanced transport efficiency and reduced transport costs. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Pallet Transport`}</strong>{` `}</p>
        <p parentName="li">{`A pallet works as a horizontal platform unit on which goods are placed. Pallets are used for collecting, distributing, storing, moving, and transporting goods. Bulks of goods are placed onto such platform and bundled as required to create a transport unit, to ease the process of mechanical loading/unloading, handling, and stacking amid transport.
Pallets are usually made of wood, plastic, glass fiber, or metals. A pallet can be added with upper fittings, which fall into the most common three types based on their structures:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Flat pallet -- made up of two- or single-layer board(s) with base supports.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "481px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "38.61003861003861%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABcRAAAXEQHKJvM/AAACRUlEQVQoz12SS0xTYRSEL1HEDSQG4taVa7e60Ji4dKcxGnxAwIqaqBWkFCwiUMUoFXwgr2CpthQoRLgNWguCENIoETWACSgECm14JBTBy+OW2/8zxejCWZyZMyeZzRmJ/7C5voy6Mk8kvP7XEtERUFQl9GtVQ9uAiCoURUGoa0R3LawyEwggyzJSZC3E+sIoobFuFgabCfZW4O8sZaavirnBFhbH+wUr09ROqsp+OaC1fpzA4BwQB0u8nKzsw+Eb59O3H7jbX+FqbkLy2QsYk4sJdluY6bIw/a6MQM8jprse8r3jHp5nV8TSkIeGeRQpv1+LOd+IlGwTUpoTKdWBlGInKd3GEVMDdQ4X0nBvG60lqQw05DPhKWWys4wvrWa8tTl8br0b1YKfU7wMCkUq9mlxuR7i9G0iXt9Ggs5JlGP1MkkGmccvWpAWRjxYTSco1B2luSSdqZ4K3E/0WIvSsJtTqTQeE8pQG/ZZFKl8WNuZ62Gbvl3E5LxhR3YHsZludhlkzt1voc7RjDTnH8VVZaY27xTWghS81Tdwl1/C9eAibx0WfO9fC5QF6oMRRSr6oO3OaCJJ1yhiM2W2Gz0kZriIP21lT4aNEls70oa6yfziMt7GCnz1WXirDYz0NLI462d1YxM18ufLVr+q7Lvj0g5dsJCY7hSJ1ztISK5hr+45hy+XcfZqIU9r6pAiEW2rG0uhRebGfKhK6F9XordwOLwVOPB1SDEZsjSzKYfjWaXiQHY9Z64Vk2O8iflWHuZ8I7fzjPwGXEbe3HEtXHYAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e2c04cade3ac3314913272c09efb42df/652f5/transport-07.webp 259w", "/static/e2c04cade3ac3314913272c09efb42df/1c567/transport-07.webp 481w"],
                    "sizes": "(max-width: 481px) 100vw, 481px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e2c04cade3ac3314913272c09efb42df/a2ead/transport-07.png 259w", "/static/e2c04cade3ac3314913272c09efb42df/d024a/transport-07.png 481w"],
                    "sizes": "(max-width: 481px) 100vw, 481px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/e2c04cade3ac3314913272c09efb42df/d024a/transport-07.png",
                    "alt": "Flat pallet transport",
                    "title": "Flat pallet transport",
                    "loading": "eager",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Box pallet -- a flat pallet serves as the base, on which a box-like structure is installed, enclosed by mesh or ordinary board, with or without cover at the top.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "421px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "46.71814671814671%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcRAAAXEQHKJvM/AAACq0lEQVQozzWPa0zNcQCGfzpaSizpg7uZNluJuVSkdIpp0Rozl8UIYzUll5MWnVZa5aiUlS4qdJkuKjtRpwsnR6jTZc5xacwXJRpfXPKh6df/sdPm3Z7tffZ+egVgGf/zxzLyedTy/sNHy8vefkv74y7Lp+HPFttmI6Nq8Pei8Fq8jj1V5oc8wnWHgVnbO7EPfsL8rTUsWB3K7mWBxHtFIB61Gki4rCUjK4eLl5PRpqZxPDqW2AsXyb5RwNCQlczil4h1dYrdtmfYq3tQ+T1DpTbiuOMVc4LNLHHfRvIed24e2YDQP2yRh45GyujTUXLfwf3y7LkzMuJopIxP1MrDx6Nkuk4nCyr7FKegDmaGvlXs1WZU3gZmBL1gdlAv8/w6cHXzoCjcgfoDSxFGUzcnomOIPa8h49p10jKzybtZSnlFLSkZOXQYu2hse82SoDJctpThvL4YB49cZq0vwSWgEZcAPc6rzuG7KYbDwZGIV1YrUbFxnE1IJL/4NvnF5dy910B5dT3xSWn09A/Q2GhihbqSOf7NOPg04bi6BCc/PctCu1gYZkb4mxEBgzh5XkcAssXQJo+cPCULS+/K3MJSmVtYJi+lpMt0Xbac+DshjXUPFM/tNSzYNaC4qLuZu7YM10ADy3d2sXhnNzO3PMYh0ISzZw5CURRsaW3vRJdbQJwmgSZ9M1/HxpBSTm8tdbWsURcorv5NuPlWsdArBTefO2ze28ma8A5UGxtQbWpFrExFTE1NmQHzz1+/zUZTt3l4ZHjagT5Foc/WH9bX/EpNzCJPd0u5os0kOTGVuLir+IdX4BnWjvDWY+dTT8i+TMTk5KTttvg0Mip81CFCE6+Z9v9MjP8QX6zP28esJr6/ey6/DfUy+qaH5oYGkrTZ7DlZTURUPlnXirhfVc0/1HTHsQMdWpEAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/ca6f668bee99e564bf1dbfdf8800549a/652f5/transport-08.webp 259w", "/static/ca6f668bee99e564bf1dbfdf8800549a/ced8d/transport-08.webp 421w"],
                    "sizes": "(max-width: 421px) 100vw, 421px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/ca6f668bee99e564bf1dbfdf8800549a/a2ead/transport-08.png 259w", "/static/ca6f668bee99e564bf1dbfdf8800549a/092ed/transport-08.png 421w"],
                    "sizes": "(max-width: 421px) 100vw, 421px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/ca6f668bee99e564bf1dbfdf8800549a/092ed/transport-08.png",
                    "alt": "Box pallet transport",
                    "title": "Box pallet transport",
                    "loading": "eager",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Post pallet -- It has a flat pallet as the base, a pole at each corner, and a mobile tracker at either transverse side, through which the length or height can be adjusted when putting goods onto the pallet.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "394px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "46.33204633204633%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcRAAAXEQHKJvM/AAACo0lEQVQozx3M209ScQAH8KMPrvXWest1WZuztlKztGUzreZazktNM1G5CHkBBAWOR0W5HRAQ5C4iHO4oxtSmWS5ds/6Eemxzv7fW1rOvP75tff6AD1NZwZCKykrCVFQShmFIbc1N8vzhPdLaWEfqbtWQmhvXyPWr1aTqwsWzz0dfzk8PPmAnLZTfCxuIOqxIrrrx7XAfv37+wN/ff8Awly+h6ko1GpqbMSMXIePgUPKasL2yiC23EWk7B8GqR1dHGzL5IkQtTeWBBw3gBl7BNS7DqnIUq+NiOIZ6cSCkwNQ+fUFVY0N0N2SmJ4KHHid99CQdoF8zYXqai9DvhSg92nDSwb5uauM95eHWFgw/ai7zUhEKZg75JRYpTgOfYgAZVwDMzKwLrpkJhA0y+HRihFgJYvMKpC1qbDr12I9YkbQboNGwkEjU6Gmsh6ztMWxS0f8sPTcNgVUiMDaIoNEOZtERg53VYdOziINUELvRZWz7TdjyLaHgNWJF3Q+3Rgzbog3t7b3orL8DxbMnsIjfImvUQWCnENdPIjg+BF7DgTG5EnAZ57Hn4/BxfRm7IR5FzwJKASsOEl5EDCNwauVwu8Lo7x1B9/16qHo6YZGKkFnQQTCokTBMYk0tgVmtA+P2ZajXtERtKikNsnK6xatpkVfSvGWCxufl1D/VT5dnDdQfECint5W7Gu9C3tFeto+NIjWvQ5KbRtaoR0Irh2VqCsx6YgexxC48gRwsNj/8Rha8dgzhBTV2PBxyDgPcy36sR7Pg+RCU71TlvqbbsI+OIL80ixSrxJpWBrPiDTTKCTCRaInE4nskW/hENrePSSZ3SAKRPDGbPcQ5oyRrJpZsxIsknSqdmczOc/2cFXKpuKx5/RLOSTF0ikGoVBNQ6WahYRfwD0RUqShZV3YjAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/3a88f4e4099111e3cd43051931c0a727/652f5/transport-09.webp 259w", "/static/3a88f4e4099111e3cd43051931c0a727/47f09/transport-09.webp 394w"],
                    "sizes": "(max-width: 394px) 100vw, 394px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/3a88f4e4099111e3cd43051931c0a727/a2ead/transport-09.png 259w", "/static/3a88f4e4099111e3cd43051931c0a727/cc097/transport-09.png 394w"],
                    "sizes": "(max-width: 394px) 100vw, 394px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/3a88f4e4099111e3cd43051931c0a727/cc097/transport-09.png",
                    "alt": "Post pallet transport",
                    "title": "Post pallet transport",
                    "loading": "eager",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>{`  `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Sliding pallet -- mainly divided into pallets with plastic or paper sliding devices.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "433px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "42.857142857142854%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcRAAAXEQHKJvM/AAAChUlEQVQozz2R20+ScQCGv3/IzXXTXV04p9ZsTE0r52pNbZUCkkiuzIXWOkhK2sGhlCAeaJInQCAQdRq2LpSCtEyUk+inqCnfL/H7fm+DVe/2bs/Vc/MwlNJQ6hxHQptbbAhAiBeEEM/zIQqEf3OHayZtO9eobMJDdSuVycQQiQqgbnmMT3NuTDsmYOrrhaZDjddtKjCJBIfUDg8P8TOwmubjYx48z6c5wRG+QtlBihV1UOpfUK3ZiNL6ZlyQymHxeGBzTkFc3wCL3YGW7n4we3v7AgBhOx4XAutBgVIqUCoIPM8L4UgE66EId6LwCjlfV41Ocz91fHSiSFqH/Krr8AS+weSawqniizC6JqHsHQATCIbB7uwixu4gxsYRY3exf5AAzx/TLz4/AmtBclJ0iZTerUWXfYCOz9lQIFHgnFiC2RU/Bp1uZF0uh97mgKLrLRjf0jL2EwQkKWDRtwT37DwC0S2srEfogteLcDRKMgtlJLteBengEK3tfYcs+TPkN6jwaNQCpWEEBfIHaBu2QdJpBMNxCcS2dxD/lcC05zMc7hksr0bwSqOjk+4psCxLMnJLSI5UjGarjnY4dDgrluBMtRijCzPQjI4g52olBpx2yNrUYCilODo6QnSTxeSsBz19RlhsLkSiUZqKEg5HSGaeiBQqqtFh1VOtrRtl8lvIrSjH8LwZb8aMKKmRYGxmHGX3G8Gkav4rane5oDcY8HdpYXQjRjKyikj2jUq0mnvoywkd8m7KcPpaFVqtRkiet6NYdg+aERPuPFGBEQThv3AtuA6W3UpzMplMC/ficXJbWkOqm56i74ONvreOolOrh2FoHH6fF37vIla++rDx4zsOwgH8AQgVNebZYwaZAAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/562a3049c023bcb3fc264da44c4841bc/652f5/transport-10.webp 259w", "/static/562a3049c023bcb3fc264da44c4841bc/aff3a/transport-10.webp 433w"],
                    "sizes": "(max-width: 433px) 100vw, 433px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/562a3049c023bcb3fc264da44c4841bc/a2ead/transport-10.png 259w", "/static/562a3049c023bcb3fc264da44c4841bc/55fc0/transport-10.png 433w"],
                    "sizes": "(max-width: 433px) 100vw, 433px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/562a3049c023bcb3fc264da44c4841bc/55fc0/transport-10.png",
                    "alt": "Sliding pallet transport",
                    "title": "Sliding pallet transport",
                    "loading": "eager",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>{`  `}</p>
          </li>
        </ul>
      </li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Advantages`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Limitations`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Shorter transport time and less labor intensity`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Limited scope of the goods to be transported. Good for small-sized, boxed or carton-packaged goods. Not suitable for large-sized, irregular-shaped, or bulk frozen goods.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Eases counting and handing over, reducing cargo loss or damages.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Increased pallet costs with a corresponding reduction in carrier capacity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Small investment for instant results.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Failing to serve the requirements of international multimodal transport`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`  `}<strong parentName="p">{`Specifications and dimensions of pallets`}</strong>{` `}</p>
    <p>{`  The International Organization for Standardization (ISO) specifies four types of pallet specifications as follows:      `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`1200mm*1000mm, rectangular, widely used in Europe. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1200mm*800mm, rectangular, widely used in Europe. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1140mm*1140mm, square, widely used in Australia. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`40” (1016mm) *48” (1219mm), rectangular, widely used in the USA.  `}</p>
        <p parentName="li">{`The size 1200mm x 1000mm pallet is the most common type to be used around the globe. This is also the most common one in China.`}<br parentName="p"></br>{`
`}{`In Asia, square pallets of the size 1100mm x 1100mm are also frequently used.        `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Container Transport`}</strong></p>
      </li>
    </ul>
    <p>{`Container is a mode of transport that makes up bulk cargo, sundry cargo, and other special cargo. Container transport is one of the modern transport modes where containers serve as units to transport goods. It suits waterway, railway, multimodal transports, etc., with following advantages and disadvantages: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Advantages`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Limitations`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`It enhances the quality of transport and reduces the risk of freight loss and damage.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Big dead weight leads to a big proportion of ineffective transport and loading/unloading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Reduces various fees and freight costs.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The high cost of containers would be apportioned to each transport in logistics.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`It improves efficiency in loading/unloading and accelerates the turnover of transport means.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Container empty returns may build up transport costs of unit goods, and bring down their utilization.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`It simplifies freight procedures and facilitates the transportation of goods.`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Multimodal Transport`}</strong></li>
    </ul>
    <p>{`Multimodal transport means the carriage of goods by at least two different modes of transport on the basis of a contract from a place in one country at which the goods are taken. The process is managed by the multimodal transport operator to a place designated for delivery, situated in a different country. For example, sea-land transport, sea-rail transport, sea-air transport, air-land transport, sea-rail-land transport, etc.  `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "791px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.14285714285714%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChUlEQVQoz32T6UuUURSH37+hLxG0CWVEkRnRRhYZfhBFRx33TEK0GhEzDUJBCsMMSapxI00lx30JTDNplHGnlGwcM/XVNLf0VQd1dEydmSfcQMF64MfvcLn3cM699whsw2KxrGsr3r6+hmRYpm9YAkyYLRZMJtOmzOu+tk/YfmDNzWbzukybvhWvMTZj4FJMNTm1vfwLYbeK/oe64xeH3J6QlaVCo66ltq6BltY26uubGBuf2Eho3qxgcdFIl+47Wq2Or7puRFFEmpKYNywwPDrGyNAgGcnJPI6J4YprCM53nlLzScPQ8ChNzZ/R62c3Wt6iWxwg0N8fhY8Pnp4uKO6FcPPuDUIVfoQFu5OQkk5cVT9+JVNk1fVR1y7CytLOlufm5pAmJ+kXRVJS0/Dy8UXu7YuH3InAkPv4RpZg7xnDOXt3vNLb2ZskITzsI6pMZHxaj3pwlpqRFb78+I34U0IIjYrmuswbJ99b2DnLuerqw0VHd2yvOREem02sUssZWTbCHnuOhpUTpOrB5ZmaBykVeCbkEZChJr+pG2VhM6n5zQhqTQMnz15mv9VxrI7ZcNj6FAeOnGDfQWsc3cLxUuRga3cb74BIIuLfUFRcQa6qgJzCEnp0WiYHBxgTu4nO1BCa2YFQUFhEmMwNmc1pnM5fQO7gQERwMMrERPLzSkl/XcwLpYrcvEryVGUkJaVRXvqOj1VVVL+vJOWlErnMg+AgBZUf6hAaG5up19TT0tKKtlPHxKSEedslTxugb9hAa7tIW+8EM7MLdGq7KCsuJf+tilfPk4h/FEdGRi7fuvoRjEYjf5aXWV1d3TEZW1OwYjIzu7CCpDcyv7r7X10yGsFiWn/lv8BUxYiTucpjAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b714fb32d004ee4ef935c198ec8e8f31/652f5/transport-06.webp 259w", "/static/b714fb32d004ee4ef935c198ec8e8f31/c29d2/transport-06.webp 518w", "/static/b714fb32d004ee4ef935c198ec8e8f31/5f564/transport-06.webp 791w"],
            "sizes": "(max-width: 791px) 100vw, 791px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/b714fb32d004ee4ef935c198ec8e8f31/a2ead/transport-06.png 259w", "/static/b714fb32d004ee4ef935c198ec8e8f31/6b9fd/transport-06.png 518w", "/static/b714fb32d004ee4ef935c198ec8e8f31/cc8d6/transport-06.png 791w"],
            "sizes": "(max-width: 791px) 100vw, 791px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b714fb32d004ee4ef935c198ec8e8f31/cc8d6/transport-06.png",
            "alt": "Multimodal Transport",
            "title": "Multimodal Transport",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "faqs"
    }}>{`FAQs`}</h2>
    <p>{`Choice of couriers provided by NexPCB: `}</p>
    <p><strong parentName="p">{`S. F. Express`}</strong>{`: falls into highway (land) transport and air transport, somewhat varying in the price and time limit.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`I07`}</strong>{`: multimodal transport -- goods are transported via highway to Shanghai, and then shifted to water transport, namely, they are shipped via sea to a port in USA, and then transported via highway to customer’s warehouses; I07 also offers value-added services -- pallet transport -- flat pallet.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Beeper transport in batches`}</strong>{`: multimodal transport -- beepers are transported via highway to Shanghai, and then shifted to water or air transport to a port in the USA, and then transported via highway to customer’s warehouses.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`International express`}</strong>{`: multimodal transport -- goods are transported via highway to Shanghai for customs clearance, then shifted to air transport to a nearby city in the destination country, and are transported via inland highway to customers.  `}</p>
    <p><strong parentName="p">{`Q`}</strong>{`: There’s a small international parcel that the customer needs urgently, and what is the fastest way to make it? `}</p>
    <p><strong parentName="p">{`A`}</strong>{`: How much is the value? Do you need to declare at the customs? What is the name of the goods? Does it contain lithium batteries or liquids or magnets?`}</p>
    <ul>
      <li parentName="ul">{`A customs declaration within USD600 can be done quickly. Otherwise, a formal declaration is required and it will take one to two working days.`}</li>
      <li parentName="ul">{`If the product has ever been declared before, the template can be used. Otherwise, the process of financial tax refund filing should be considered, and a report shall be made in advance.`}</li>
      <li parentName="ul">{`For a product with lithium batteries, certificates shall be handled for direct transport. Otherwise, it needs to be forwarded via a freight forwarder in Hong Kong.`}</li>
      <li parentName="ul">{`For products with magnets, extra inspection is needed, which would take a longer time.`}</li>
      <li parentName="ul">{`For products with liquids, a confirmation shall be made with agents in advance to make sure whether such product can be transported.`}</li>
      <li parentName="ul">{`For all these issues involving transport cost and time, a comprehensive consideration is needed. Both our PM and Sales can assist you with it. `}</li>
    </ul>
    <p><strong parentName="p">{`Q`}</strong>{`: What is the weight range suitable for air freight? Since express delivery is similar to air freight, what is the difference between them? `}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Generally, air freight is considered for a charged weight of over 100 kilograms, since 100 kilograms is the starting weight for charging for air freight.
The differences between express delivery and air freight lie in:`}</p>
    <ul>
      <li parentName="ul">{`Different service locations: express offers door to door service, while air freight transports goods from one airport to another`}</li>
      <li parentName="ul">{`Different procedure handling: easier handling for express since you only need to fill out a waybill and enclose the invoice. Airfreight handling is more complicated, as you need to handle a whole package of paperwork for customs declaration, including guarantee letters for non-hazardous goods and declaration.`}</li>
      <li parentName="ul">{`Different volume requirements: small parcels are suitable for express, while large-sized goods are for air freight. `}</li>
      <li parentName="ul">{`Different duration: they both take basically the same time, sometimes express is faster, and sometimes air freight is quicker, depending on each case.`}</li>
      <li parentName="ul">{`Different service facilitation: for express, you merely need to make an appointment, and your parcels would be directly collected. For airfreight on the FOB basis, you need to transport your goods to and unload them at an appointed warehouse. However, under EXW terms, you and your agent need to reach a consensus over the time of picking up goods, and then the agent arranges a driver to pick up such goods from the supplier’s warehouse.`}</li>
    </ul>
    <p><strong parentName="p">{`Q`}</strong>{`: How does the whole transport system work? Can you show us the steps? `}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Taking the example of the transport process using our international express delivery:`}</p>
    <ol>
      <li parentName="ol">{`Waybills and export declaration documents made by the exporter.  `}</li>
      <li parentName="ol">{`Pickup is scheduled.  `}</li>
      <li parentName="ol">{`Handing over of goods. `}</li>
      <li parentName="ol">{`Export declaration.  `}</li>
      <li parentName="ol">{`Goods leave through ShangHai. `}</li>
      <li parentName="ol">{`Forwarding between airports and ports. `}</li>
      <li parentName="ol">{`Goods arriving at the destinated country.  `}</li>
      <li parentName="ol">{`Forwarding to destinated city.  `}</li>
      <li parentName="ol">{`Arrival at destinated city's port. `}</li>
      <li parentName="ol">{`Import customs clearance and duty payment. `}</li>
      <li parentName="ol">{`Forwarding and delivery. `}</li>
      <li parentName="ol">{`Importer signing delivery letters when goods are received.`}</li>
    </ol>
    <p>{`In short: `}<strong parentName="p">{`delivery -- export declaration -- import clearance -- delivery`}</strong>{`. `}</p>
    <p>{`In this case, the fees charged vary according to different weights and different packages. For transport, the Sales and PM on our team need to communicate with the logistics operator of the partner. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      